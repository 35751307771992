<template>
  <div class="input-list-control">
    <label class="input-label">{{label}}</label>
    <input class="input-control" type="text"
           v-model="search" :placeholder="placeholder">
    <div class="absolute inset-y-0 right-0 flex items-center px-2" @click="clearSearchData" :title="search ? 'Clear' : 'Select route'" :class="search ? ' pointer-events-auto cursor-pointer': ' pointer-events-none'">
      <svg v-if="!search"
          class="w-6 h-6 text-gray-type-3 fill-current bg-white"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20">
        <path
            d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
        />
      </svg>
      <svg v-else class=" w-6 h-6 p-1 text-red-600 fill-current bg-white" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" width="24px" height="24px"><path d="M 4.9902344 3.9902344 A 1.0001 1.0001 0 0 0 4.2929688 5.7070312 L 10.585938 12 L 4.2929688 18.292969 A 1.0001 1.0001 0 1 0 5.7070312 19.707031 L 12 13.414062 L 18.292969 19.707031 A 1.0001 1.0001 0 1 0 19.707031 18.292969 L 13.414062 12 L 19.707031 5.7070312 A 1.0001 1.0001 0 0 0 18.980469 3.9902344 A 1.0001 1.0001 0 0 0 18.292969 4.2929688 L 12 10.585938 L 5.7070312 4.2929688 A 1.0001 1.0001 0 0 0 4.9902344 3.9902344 z"/></svg>
    </div>
    <div class="options-wrapper" >
      <div v-for="item in filteredData"
           :key="item[bindKey]"
           @click="updateSelectedValue($event, item)"
           class="option cursor-pointer">
        <input type="radio" :checked="item[bindKey] === selectedValue[bindKey]">
        <label class="text-xxs">{{item[bindLabel]}}</label>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "InputListSelector",
  emits:['item-selected'],
  props: {
    label: {
      type: String,
      required: true
    },
    bindKey: {
      type: String,
      required: true
    },
    bindLabel: {
      type: String,
      required: true
    },
    value: {type: Object},
    data: {type: Array},
    description: {type: String},
    placeholder: { type: String }
  },
  data() {
    return {
      selectedValue: null,
      search: '',
    }
  },
  created() {
    if(this.value) {
      this.search = this.value.label;
      this.selectedValue = this.value;
    } else {
      this.setDefaultSelectedValueData();
    }
  },
  computed: {
    filteredData() {
      return this.data.filter((item) =>
          item[this.bindLabel].toLowerCase().includes(this.search ? this.search.toLowerCase() : '')
      );
    },
    selected() {
      return this.selectedValue ? this.selectedValue.value : null;
    }
  },
  methods: {
    updateSelectedValue($event, value) {
      this.selectedValue = value;
      this.search = value[this.bindLabel];
      this.$emit('item-selected', this.selectedValue);
    },
    clearSearchData() {
      this.setDefaultSelectedValueData();
      this.search = '';
      this.$emit('item-selected', this.selectedValue);
    },
    setDefaultSelectedValueData(){
      this.selectedValue = {[this.bindKey]: null, [this.bindLabel]: null};
    }
  }
}
</script>

<style lang="scss" scoped>
.input-list-control {
  @apply relative w-full;

  & .input-label {
    @apply absolute z-10
    inset-y-0
    flex items-center
    pl-3 text-xs font-semibold text-gray-type-2;
  }

  & .input-control {
    @apply p-4 pl-16 flex-grow text-xs
    w-full outline-none
    bg-white border rounded
    text-gray-type-8 border-gray-type-7;
    &:focus ~.options-wrapper {
      display: block;
    }
  }
  & .options-wrapper {
    &:hover {
      display: block;
    }
    &__open {
      display: block;
    }
    display: none;
    position: absolute;
    min-width: 4rem;
    max-width: 100%;
    overflow-y: auto;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2), 0px 2px 10px rgba(0, 0, 0, 0.1);
    @apply absolute rounded-lg p-2 z-10 w-full mt-1 overflow-x-auto bg-white max-h-40;

    & .option {
      @apply text-gray-type-1 cursor-pointer hover:bg-gray-type-4 p-2;
      input[type="radio"] {

        position: absolute;
        opacity: 0;

        &:checked {
          & + label {
            &:before {
              background: theme('colors.green.type-1');
            }

            &:after {
              opacity: 1;
              transform: scale(1);
            }
          }
        }

        &:not(:checked) {
          &:after {
            opacity: 0;
            transform: scale(0);
          }
        }

        & + label {
          @apply relative pl-7 cursor-pointer inline-block leading-tight text-gray-type-2;

          &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 18px;
            height: 18px;
            border: 1px solid #ddd;
            border-radius: 100%;
            background: transparent;
          }

          &:after {
            content: '';
            width: 8px;
            height: 8px;
            @apply bg-white;
            position: absolute;
            top: 5px;
            left: 5px;
            border-radius: 100%;
            transition: all 0.2s ease;
          }
        }
      }
    }
  }
}
</style>